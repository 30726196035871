import React, { Component } from "react";

class Nosotros extends Component {
  handleRedirect(path) {
    window.location.href = path;
  }
  render() {
    return (
      <div className="item-container">
        <div className="header" />
        <div className="container">
          <div className="row">
            <div class="title-header col-12 text-center mb-5 mt-5">
              <div class="category-title">Acerca de Nosotros</div>
            </div>

            <div className="col-md-12">
              {/* Política de Calidad */}
              <div className="title-header col-12 text-center mb-5 mt-5">
                <div className="item-title">Política de Calidad</div>
              </div>
              <div class="col-12 mt-4">
                <div class="description mt-3 text-justify">
                  La Alcaldía Municipal de Chía proyecta una ciudad inteligente
                  e innovadora donde se compromete con la satisfacción de las
                  necesidades de su población, basada en la eficiente y
                  transparente administración de sus recursos, y el mejoramiento
                  continuo de su sistema integral de gestión, en coherencia con
                  las directrices nacionales, departamentales y con el plan de
                  desarrollo municipal, mediante la efectiva y eficaz prestación
                  de los servicios de salud, educación, seguridad, construcción
                  de obras de infraestructura, ordenamiento territorial y medio
                  ambiente, para garantizar el desarrollo social, cultural,
                  deportivo, económico y la erradicación de la pobreza.
                </div>
              </div>
              {/* Objetivos de Calidad */}
              <div className="title-header col-12 text-center mb-5 mt-5">
                <div className="item-title">Objetivos de Calidad</div>
              </div>
              <div class="col-12 mt-4">
                <div className="description mt-3 text-justify">
                  <ul>
                    <li>
                      Garantizar la calidad en la atención y prestación de
                      servicios de su competencia.
                    </li>
                    <li>
                      Satisfacer de manera oportuna y efectiva la demanda de
                      servicios y trámites de la población.
                    </li>
                    <li>
                      Promover el desarrollo social incluyente, cultural,
                      político, participativo, económico sustentable, físico y
                      ambiental sostenible del Municipio.
                    </li>
                    <li>
                      Asegurar la eficiencia y transparencia en la
                      administración de los recursos financieros, físicos y
                      tecnológicos del Municipio.
                    </li>
                    <li>
                      Asegurar el desarrollo integral de los Servidores
                      Municipales durante su ingreso, permanencia y retiro.
                    </li>
                    <li>
                      Mejorar continuamente la gestión municipal y sus
                      resultados en términos de conveniencia, adecuación y
                      eficacia, eficiencia y efectividad, frente al cumplimiento
                      del plan de desarrollo teniendo como referentes las
                      políticas de orden nacional e internacional.
                    </li>
                  </ul>
                </div>
              </div>
              {/* Misión */}
              <div className="title-header col-12 text-center mb-5 mt-5">
                <div className="item-title">Misión</div>
              </div>
              <div class="col-12 mt-4">
                <div className="description mt-3 text-justify">
                  Garantizar a sus habitantes una oportuna y efectiva prestación
                  de los servicios con calidad en materia de salud, educación,
                  seguridad, construcción de obras de infraestructura,
                  ordenamiento territorial, medio ambiente, crecimiento socio –
                  cultural, deportivo y erradicación de la pobreza, promoviendo
                  la participación comunitaria en aras de mejorar la calidad de
                  vida de nuestros ciudadanos y de quienes visitan nuestro
                  territorio.
                </div>
              </div>
              {/* Visión */}
              <div className="title-header col-12 text-center mb-5 mt-5">
                <div className="item-title">Misión</div>
              </div>
              <div class="col-12 mt-4">
                <div className="description mt-3 text-justify">
                  En el año 2027 Chía será ciudad líder en desarrollo sostenible
                  caracterizada por una cultura emprendedora, empoderada del
                  medio ambiente, participativa, solidaria y orgullosa de su
                  patrimonio e historia.
                </div>
              </div>
            </div>
          </div>

          <div className="footer-btns container row mt-5">
            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4" />
            <div
              className="homePage col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4"
              onClick={() => this.handleRedirect(`/`)}
            />
            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4" />
          </div>
        </div>

        <div className="footer" />
      </div>
    );
  }
}

export default Nosotros;
