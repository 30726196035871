import React, { Component } from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { Link } from "react-router";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.scss";
import Slider from "react-slick";
import * as types from "../actions/types";

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      phone: "",
      email: "",
      message: "",
      subscribe: true,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {}

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSubmit() {
    fetch(
      types.PATH_SITE +
        "contacts.json?names=" +
        this.state.name +
        "&phone=" +
        this.state.phone +
        "&email=" +
        this.state.email +
        "&message=" +
        this.state.message +
        "&subscribe=" +
        this.state.subscribe,
      {
        method: "GET",
      }
    )
      .then((response) => response.json())
      .then((message) => this.setState({ message }))
      .catch((error) => this.setState({ error, isLoading: false }));
    this.handleRedirect("/contact");

    event.preventDefault();
  }

  handleRedirect(path) {
    window.location.href = path;
  }

  render() {
    const { message } = this.props;
    return (
      <div className="product-container">
        <div className="header" />
        <div className="container">
          <div className="title text-center pt-5 pb-5">
            <div className="subtitle-product">Formulario de Contacto</div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-2" />
            <div className="col-md-8">
              <h2>
                {message} {message !== undefined ? message : null}
              </h2>
            </div>
            <div className="col-md-2" />
          </div>

          <div className="row">
            <div className="col-md-2" />
            <div className="col-md-offset-2 col-md-8">
              <form onSubmit={this.handleSubmit} className="form-horizontal">
                <label>Nombres:</label>
                <input
                  className="form-control"
                  name="name"
                  type="text"
                  value={this.state.name}
                  onChange={this.handleInputChange}
                />

                <br />
                <label>Telefono:</label>
                <input
                  className="form-control"
                  name="phone"
                  type="text"
                  value={this.state.phone}
                  onChange={this.handleInputChange}
                />

                <br />
                <label>Correo:</label>
                <input
                  className="form-control"
                  name="email"
                  type="mail"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                />

                <br />
                <label>Asunto:</label>
                <input
                  className="form-control"
                  name="message"
                  type="text"
                  value={this.state.message}
                  onChange={this.handleInputChange}
                />

                <br />
                <div className="custom-control custom-checkbox">
                  <input
                    className="form-control custom-control-input"
                    name="subscribe"
                    type="checkbox"
                    id="customCheck1"
                    checked={this.state.subscribe}
                    onChange={this.handleInputChange}
                  />
                  <label className="custom-control-label" htmlFor="customCheck1">
                    Desea inscribirse a nuestro boletin:
                  </label>
                </div>
                <br />
                <input
                  type="submit"
                  value="Enviar"
                  className="btn btn-primary btn-lg btn-block"
                />
                <br />
                <br />
                <br />
              </form>
            </div>
            <div className="col-md-2" />
          </div>

          <div className="footer-btns container row">
            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4" />
            <div
              className="homePage col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4"
              onClick={() => this.handleRedirect(`/`)}
            />
            <div className="col-4 col-sm-4 col-md-4 col-lg-4 col-lx-4" />
          </div>
        </div>

        <div className="footer" />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    product: state.data.product,
    products: state.data.products,
    banners: state.data.banners,
  };
}

export default connect(mapStateToProps, actions)(Contact);
