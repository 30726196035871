import React, { Component } from 'react'
import tawkTo from "tawkto-react";

const tawkToPropertyId = '5e0609c77e39ea1242a20347'
const tawkToKey = 'default'

export default class App extends Component {
  componentDidMount(){
    tawkTo(tawkToPropertyId, tawkToKey)
  }
  render() {
    return (
      <div className="main-content">        
        <div className="content-body">        
            { 
              this.props.children 
            }  
        </div>  
      </div>
    )
  }
}
